import React from 'react';
import SEO from '../components/SEO';

const Page404 = () => {
  return (
    <>
      <SEO title="404"/>
      <h2>404 error</h2>
    </>
  );
}
 
export default Page404;